/* Middle Accordion */
.accordion-middle-side {
  margin-bottom: 0;

  .fa-2x {
    font-size: 1em;
    margin-right: 3px;
    cursor: pointer;
  }

  .table {
    margin-bottom: 0;

    td {
      text-align: center;
    }
  }

  @media all and (max-width: $screen-md-max) {
    margin-right: 0 !important;

    #slide-2 .info {

      .form-group {
        width: 100%;
      }

      textarea {
        width: 100%;
        height: 64px;
        resize: none;
      }
    }
  }

  @media only screen and (max-width: $screen-sm-max) {
    .row .form-group {
      width: 100%;
      margin-bottom: 10px;
    }

    .acc-slide-7 {

      .form-group {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }

  @media only screen and (max-width: $screen-xs-max) {
    .acc-slide-7 {

      select {
        margin-bottom: 10px;
      }
    }
  }
}

/* Right Accordion */
.accordion-right-side {
  padding: 0;
  margin-top: 10px;

  .panel-body {
    border: none;

    .highlight {
      border-radius: 0 0 4px 4px;
      border-left: none;
      border-right: none;
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  textarea {
    width: 100%;
  }

  .set-up {
    position: absolute;
    top: 2.2em;
    right: 12px;
  }

  .panel {
    margin-bottom: 5px
  }

  .panel-body {
    padding: 0
  }

  .highlight {
    position: relative;
    margin: 0;
    border-radius: 0;
    border: none;
  }

  @media all and (max-width: $screen-sm-max) {
    .form-group {
      width: 100%;

      &.set-up {
        width: inherit;
      }
    }
  }
}

/* Left Accordion */
article.note {
  width: 280px;
  margin-right: 32px;
  overflow: auto;
  .btn-switcher {
    position: absolute;
    z-index: 2;
    left: 286px;
    top: 4px;
    color: white;

    .fa {
      font-size: 22px;
      padding: 0;
      cursor: pointer;

      &.fa-caret-square-o-left {
        color: $colorWhite;
      }
    }
  }
  &::after {
    color: white;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 280px;
    height: 100%;
    width: 32px;
    border-radius: 3px;
    z-index: 1;
    background: $colorBlue;
  }
  .note-head {
    position: absolute;
    top: 58px;
    left: 248px;
    color: white;
    display: inline-block;
    font-size: 16px;
    width: 94px;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    z-index: 2;
  }
  .note-head-contact {
    position: absolute;
    top: 38px;
    left: 272px;
    color: white;
    display: inline-block;
    font-size: 16px;
    width: 45px;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    z-index: 2;
  }
  &.w32 {
    width: 0px !important;
    padding: 0;
    &::after {
      left: 0px;
    }
    .note-head {
      top: 48px;
      left: -34px;
    }
    .note-head-contact {
      top: 22px;
      left: -8px;
    }
    .btn-switcher {
      left: 6px;
    }
  }
}

.accordion-financials {
  &__table {
    td {
      white-space: nowrap;
    }
  }
}
