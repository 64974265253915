.widget-resizeable {
  resize: both;
  overflow: auto;
}

.react-draggable {
  position: absolute;
}

.react-resizable.panel {
  margin: 0;
}

.panel.panel-widget {
  display: flex;
  flex-direction: column;
  border-color: #aaa;

  .panel-body {
    overflow: hidden;
    flex: 1;
  }
}

.widget-header {
  display: inline-flex;
  align-items: center;
}