.navigation {
	&--main {
		padding-left: 1em;
		padding-right: 1em;
		margin-top: 1em;

		.btn {
			text-decoration: none;
			margin-left: 5px;

			.fa {
				float: left;
				padding-top: 3px;
			}

			@media screen and (max-width: $screen-sm-max) {
				margin-bottom: 5px;
			}
		}
	}

	&__heading {
		color: #fff;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	&__buttons {
		align-self: center;
		text-align: right;
	}
}