.nav.search-nav {
	margin-bottom: 16px;

	li {

		a {
			text-decoration: none;
			font-size: 12px;
			padding: 4px 15px;
		}
	}
}