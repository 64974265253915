.project-footer {
  padding-top: 6px;
  padding-bottom: 12px;
  text-align: right;
  background-color: $bg-color;

  .btn {
    text-decoration: none;
    margin-left: 8px;
    font-size: 12px;

    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: 8px;
    }

    .fa {
      margin-right: 4px;
    }
  }
}