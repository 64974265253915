$path-font: "../fonts" !default;
$path-image: "../img" !default;


$colorWhite: #fff;
$colorBlack: #000;
$colorBlue: #337AB7;
$colorGrey: #fdfdfd;
$colorRed: #ff0000;
$colorGrey-dark: #e1e1e8;
$colorGrey-dark-80: #ddd9d9;
$colorGrey-dark-500: #adb5bd;
$colorGrey-dark-900: #212529;
$colorGray-light: #f5f5f5;
$animationDuration: 0.5s;

$screen-xl-max: 1599px !default;
$screen-xl-min: 1600px !default;
$screen-xxl-max: 1919px !default;
$screen-xxl-min: 1920px !default;

$screen-xs-max:              ($screen-xl-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

$fontCalibre: 'Calibre', Helvetica, Arial, sans-serif;
$fontAwesome: 'FontAwesome';

/* Modal */
$modal-bg: #eee;
$modal-height: 260px;


$tablesTheadColor: $colorGray-light;
$tablesTheadCellpaddings: 2px;

$notes-header-height: 27px;

$input-height: 19px;