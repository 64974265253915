.s3-file-manager {
  padding-top: 12px;
  padding-bottom: 12px;

  .s3-file-manager-spinner {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .rst__tree {
    height: calc(100vh - 120px) !important;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .rstcustom__row {
    font-size: 16px;
  }

  .rstcustom__rowContents,
  .rstcustom__rowLabel,
  .rstcustom__rowToolbar,
  .rstcustom__toolbarButton {
    padding-right: 5px;
  }

  .rstcustom__rowWrapper:hover {
    opacity: inherit;
  }

  .s3-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .s3-title h3 {
    margin: 0;
  }

  .btn-s3-upload {
    margin-left: 10px;
  }

  .s3-search-group {
    width: 300px;
    margin-bottom: 20px;
  }

  .s3-search-group .input-group-addon {
    font-size: 12px;
  }

  .s3-search-group .form-control {
    height: auto;
  }
}

.s3-filename {
  text-decoration: none;
}

.s3-folder {
  color: #f9bf63;
}
