.company__nda-block {
    display: flex;
    align-items: center;

    button {
      margin-left: 10px;
      height: 19px;
      padding: 0 5px;
    }
  }

.companyInfoForm {
    .addressGroup {
        position: relative;

        .form-control[name=address] {
            padding-right: 20px;
        }
        i {
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }
}
.eventTooltip {
    position: absolute;
    background: #FFF;
    border: 1px solid #888;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 5px 10px;
    -webkit-box-shadow: 1px 1px 5px #888;
    -moz-box-shadow: 1px 1px 5px #888;
    box-shadow: 1px 1px 5px #888;
}

.currency::before {
    content: "$";
}

.accordion-middle-side .company-target__fiscal-years-table {
    td {
        white-space: nowrap;
        min-width: 6em;
        text-align: left;

        input {
            padding: 0;
        }
    }
    td:last-child {
        width: 100%;
    }
}

div.accordion.note {
    display: flex;
}

.com-tg-deal-note {

  @media (max-width: 1199px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  > div {
    align-items: stretch;
    overflow-y: auto;
  }
  .note-content {
    overflow-y: auto;
  }
}

@media (max-width: 1199px) {
  .accordion.note {
    min-height: 40px;
    margin-bottom: 10px;

    .react-resizable {
      width: 100% !important;
    }
    .note-close {
      height: 30px!important;
      margin-bottom: 10px;
      h3.title {
        position: absolute;
        top: 0;
        left: 0;
        transform: none;
        background: 0 0;
        color: #fff;
      }
    }
  }
}

.n-label {
  label {
    font-weight: normal;
  }
}

.com-target-desc {
  line-height :initial;
  font-weight: normal !important;
  font-size: 11px !important;
  height: 88px !important;
  width: 100%;
}

.com-target-note {
  height: 67px !important;
  line-height: initial;
  font-size: 11px !important;
  font-weight: normal !important;
}

.completed-by {
  label {
    font-weight: bold;
    padding: 10px 0 0 0;
  }

  padding-bottom: 10px;
}

.adr {
  a {
    text-decoration: none;
  }

  .list-item:hover {
    background-color: #eee;
    cursor: pointer;
  }
}

.item-sorter {
  display: flex;

  .fa {
    top: 0;
  }
}

.block-highlight > li {
  max-width: none;
  white-space: nowrap;
}

.nav-scroll {
  margin-bottom: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-wrap: nowrap;
}

.nav-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.nav-scroll::-webkit-scrollbar-thumb {
  background: #828182;
  border-radius: 5px;
}

.nav-scroll::-webkit-scrollbar-track {
  background: hsla(0, 0%, 100%, 0.08);
}

// Company retainer/agreement section.

.formRetainers {
  margin-top: 10px;
  height: calc(100% - 70px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 10px;
}

.formRetainersItem {
  margin-bottom: 25px;
  border: 1px solid #ccc;
  padding: 10px;
  position: relative;

  &Title {
    margin-top: 0;
    font-weight: bold;
    padding: 0 10px;
    background: $white;
    display: inline-block;
  }

  &TitleWrap {
    margin-top: -18px;
  }

  &Delete {
    position: absolute;
    background: transparent;
    right: 0;
    top: 0;
    z-index: 1;
    border: 0;
    color: $red;
    font-size: 16px;
  }
}

.tail-months-input {
  max-width: 36px;
  margin-right: 4px;
}

.tail-months-input::-webkit-outer-spin-button,
.tail-months-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.agreement-tail .last-introduced-label,
.agreement-tail .termination-label {
  font-weight: bold;
}

.formRetainersItem.new-agreement {
  border: 2px solid rgb(243, 165, 165);
}
