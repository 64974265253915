.panel-heading {
  cursor: pointer;
  padding: 6px 15px;

  i {
    font-size: 11px;
  }

  b {
    font-size: 12px;
  }
}

.panel-title {
  font-weight: bold;
  font-size: 12px;
}

.panel-body {
  padding: 5px 0 0;

  .highlight {
    padding: 10px 6px 0;
    margin-bottom: 0;
  }

  p {
    padding: 5px 10px 0;
  }
}


.panel {
  table tr th {
      border: 1px solid $colorGrey;
      background-color: $colorGray-light;
      border-bottom: 0;

    &:first-child {
      border-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
  }
}
