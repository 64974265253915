.react-autosuggest__container {
  position: relative;

  > input {
    width: 100%;
    padding-left: 3px;
  }
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 10;
  height: 300px;
  overflow-y: auto;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0;

  h4 {
    margin: 5px 0 3px;
    font-size: 16px;
  }
}

.react-autosuggest__suggestion-wrapper {
  padding: 2px 12px;
}

.react-autosuggest__suggestion--focused {
  background-color: #ddd;
}

.auto-suggestion.dropdown-menu {
  max-height: 220px;
  overflow-y: auto;
}

.autocomplete-div-hide-on-empty:empty {
  display: none;
}