  .full-screen {
    float: right;
    position: relative;
    cursor: pointer;

    i {
      position: absolute;
      top: 26px;
      left: -34px;
    }

    @media only screen and (max-width: $screen-xs-max) {
      display: none;
    }
  }
  .right-siderbar {
    overflow: auto;

    .info {
      textarea {
        width: 100%;
        height: 64px;
        resize: none;
      }
    }

    .icon {
      font-size: 17px;
      padding: 0 3px;
      position: relative;
      top: 2px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    .panel-heading {

      span.pull-right {
        float: right;
        position: relative;
        top: -16px;
      }
    }

    .plus-buyer {
      background: $colorGrey-dark;
      padding: 5px;
      cursor: pointer;
      border-radius: 4px;
      position: relative;

      span {
        padding-left: 10px;
        font-size: 12px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      }
    }
  }

  .wrap-group {
    .form-group {

      label {
        padding-top: 5px;
      }
    }
  }

  .b-project {
    .b-checkbox {
      label {
        display: inline-block;
        vertical-align: text-bottom;
        padding-left: 4px;
        cursor: pointer;
      }
    }
  }

  .table-criteria-industry {
    margin-top: 10px;
    min-height: 160px;
    position: relative;
  }

  .table-criteria-industry table {
    margin-bottom: 0;
  }

  .table-criteria-industry thead,
  .table-criteria-industry tbody,
  .table-criteria-industry tr {
    display: flex;
  }

  .table-criteria-industry tbody {
    flex: 1 1;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 130px;
    min-height: 130px;
  }

  .table-criteria-industry td {
    width: 100%;
  }

  .table-comparables .form-group {
    width: 100%;
  }

  .table-comparables.table > tbody > tr > td {
    vertical-align: middle;
  }

  .table-comparables .form-control {
    background-color: transparent;
  }

  .b-criteria {
    margin-top: 10px;

    label {
      display: block;
    }

    .form-group {
      text-align: left;
    }

    .form-control {
      textarea {
        width: 100%;
      }
    }

    #z-criteria-notes {
      width: 100%;
    }

    .form-horizontal {

      .form-group {
        margin: 0 0 15px;
      }

      .form-control {
        width: 100%;
      }
    }

    #f-criteria-notes {
      width: 100%;
    }

    .b-checkbox {
      width: 49%;
      label {
        display: inline-block;
        vertical-align: sub;
        padding-left: 4px;
        cursor: pointer;
      }
      label, input {
        vertical-align: middle;
      }

      @media (max-width: $screen-md-max) {
        width: 100%;
      }

      input {
        height: auto;
        vertical-align: top;
      }
    }
  }

  .bot-select {
    overflow: hidden;

    textarea {
      margin-top: 10px;
      max-height: 80px;
    }

    label {
      text-align: left;
    }

    .form-group {
      margin: 0;
    }
  }

  @media all and (max-width: $screen-md-max) {

    #right-side-slide-1 {

      textarea {
        resize: none;
        height: 45px !important;
      }
    }

    .balance {
      padding: 0;
    }
  }


@media all and (max-width: $screen-xs-max) {
  .wrap-table {
    overflow: hidden;
    overflow-x: scroll;
  }

  .b-criteria {
    .form-horizontal {

      .form-control {
        margin-bottom: 10px;
      }
    }
  }
}

.wrap-create-mail, .wrap-mailing-backlog, .wrap-next-actions {
  h1 {
    font-size: 18px;
    margin-top: 10px;

    span {
      font-size: 12px;
      font-weight: 400;
    }

    input[type='checkbox'] {
      position: relative;
      top: 2px;
    }
  }

  .mail-history {
    text-decoration: none;
    width: 100%;
  }

  table {
    tbody {
      tr {
        cursor: pointer;

        &:hover {
          td {
            background: $colorGrey-dark-80;
          }
        }
      }
    }

    &.t-actions {
      margin-top: 20px;

      tr {
        cursor: default;

        &:hover {
          td {
            background: $colorWhite;
          }
        }
      }
    }
  }

  .wrap-sort-order, .wrap-filters  {
    margin-bottom: 30px;

    input {
      vertical-align: text-bottom;
      margin-left: 10px;
    }

    label {
      font-weight: normal;
    }

    p {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 0;

      span {
        font-size: 12px;
        font-weight: normal;
      }
    }

    ul.sort-menu {
      margin-left: 0;
      list-style-type: none;
      -webkit-padding-start: 0;

      li {
        background: $colorGrey-dark-80;
        margin-bottom: 2px;
        width: 100%;
        padding: 2px 10px;
        font-size: 12px;
        position: relative;

        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }

        i {
          position: absolute;

          &.fa-caret-up {
            right: 23px;
            font-size: 15px;
            top: 3px;
          }

          &.fa-times {
            right: 4px;
            top: 5px;
          }
        }
      }
    }

    .w50{
      @media (max-width: 440px) {
          width: 50%;
      }
    }
  }
}

.user-logo {
  max-width: 45px;
  height: 20px;
  display: block;
  background: url($path-image + '/content/h-logo.png') no-repeat;
  background-size: contain;
}

a[href^="tel:"], a[href^="mailto:"]{
  text-decoration: none;

  &:hover {
    color: $colorBlue;
  }
}

.table {
  tr.clickable {
    cursor: pointer;
  }
}
