div.combo-select {
    button {
        border-left: none;
        padding: 0 2px 0 5px;
        outline: none;
    }
    button:hover, button:focus, button:active {
        color: #333;
        background-color: #fff;
        border-color: #ccc;
        outline: none;
    }
    button::-moz-focus-inner {
        outline: none;
    }
    li {
        a.cursor {
            color: #262626;
            background-color: #f5f5f5;
        }
        a {
            text-decoration: none;
        }
        
    }
}