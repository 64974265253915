.panel-heading {
  cursor: pointer;
}

.status-bar {
  .form-group {
    padding: 0 5px;
    .form-group {
      padding: 0;
    }
  }
}
.project-contacts {
  position: relative;
  display: flex;
  overflow-y: auto;

  .project-contacts-settings {
    top: 0;
    right: 0;
    width: 2em;

    ul {
      padding: 0;
      list-style-type: none;

      i.fa {
        font-size:  12px;
        color: $colorBlack;
      }
    }
  }
}

.t-criteria {
  .table {
    margin-bottom: 0;
  }
}

.sub-tabs-item {
  text-transform: capitalize;
  position: relative;
}


.sub-tabs-item .btn-remove-tab {
  color: $state-danger-text;
  padding: 0;
  font-size: 12px;
  border: 0;
  background: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 16px;
  height: 16px;
  line-height: 1;

  &:hover, &:focus {
    color: lighten($state-danger-text, 10%);
  }
}

.nav-tabs .add-addon-project > a {
  line-height: 1;
  font-size: 20px;
  padding: 6px 14px;
}

.tabs {
  font-size: 0;
}

.tabs input[type="radio"] {
  display: none;
}

.tabs>div {
  display: none;
  border: 1px solid $tab-border;
  padding: 10px 15px;
  font-size: 16px;
}


.tab-btn-1:checked~.content-1,
.tab-btn-2:checked~.content-2 {
  display: block;
  height: 150px;
}

.tabs label {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: $label-bg;
  border: 1px solid $tab-border;
  padding: 2px 8px;
  font-size: 12px;
  line-height: 1.5;
  cursor: pointer;
  position: relative;
  top: 1px;
}

.tabs-textarea {
  height: 130px !important;
}

.muted-label {
  color: $text-muted;
}

.tabs label:not(:first-of-type) {
  border-left: none;
}

.tabs input[type="radio"]:checked+label {
  background-color: $label-color;
  border-bottom: 1px solid $label-color;
}

.criteria-tabs {
  font-size: 0;
  margin-top: 5px;
}

.criteria-tabs .criteria-tab-btn-1, .criteria-tabs .criteria-tab-btn-2 {
  display: none;
}

.criteria-tabs>div {
  display: none;
  border: 1px solid $tab-border;
  padding: 0 15px;
  font-size: 16px;
}


.criteria-tab-btn-1:checked~.content-1,
.criteria-tab-btn-2:checked~.content-2 {
  display: block;
  background-color: $colorWhite;
}

.criteria-tabs .head-label {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: $label-bg;
  border: 1px solid $tab-border;
  padding: 2px 8px;
  cursor: pointer;
  position: relative;
  top: 1px;
}

.criteria-tabs label:not(:first-of-type) {
  border-left: none;
}

.criteria-tabs .criteria-tab-btn-1:checked+label,
.criteria-tabs .criteria-tab-btn-2:checked+label {
  background-color: $label-color;
  border-bottom: 1px solid $label-color;
}
