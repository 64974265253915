html, body, #root {
  height: 100vh;

  @media all and (max-width: $screen-md-max) {
    $bottomScrollbarHeight: 17px;
    height: calc(100vh - #{$bottomScrollbarHeight});
  }

}

body {
  display: flex;
  flex-direction: column;
  min-width: 1280px;
  font-size: 12px;
  min-height: 100%;
}

textarea.form-control {
  height: auto;
}

.container {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;

  &.bg-width {
    background: $colorWhite;
  }

  &.wrap-flex {
    display: flex;
    flex-direction: column;
    height: 100%
  }
}

.form-control {
  height: $input-height;
  font-size: 12px;
  padding: 0 3px;
  border-radius: 2px;
  box-shadow: none;

  &.input-sm {
    height: $input-height;
    font-size: 12px;
  }

  &.input-xs {
    height: 14px;
  }

  &.bold-control {
    font-weight: bold;
  }

  &.bold-control-md {
    font-size: 13px;
    font-weight: bold;
  }

  &.bold-control-xl {
    font-size: 15px;
    font-weight: bold;
  }
}

.form-group {
  margin-bottom: 2px;

  &.transactionValueAtClose {
    margin-top: -14px;
  }

  &.dealFee {
    margin-top: 14px;
  }
}

.container-fluid,
[class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.row {
  margin-left: -5px;
  margin-right: -5px;
}

.row.project-widgets [class*="col-"] {
  width: 20%;
}

textarea.project-top-panel-textarea {
  height: 110px;
}

.execute-dropdown-with-external-button {
  display: flex;
  width: 100%;
  padding-right: 5px;
}

.execute-dropdown-with-external-button .form-group {
  flex: 1;
}

.execute-dropdown-with-external-button .col-md-8 {
  margin-left: 12px;
  padding-right: 0;
  width: calc(66.66667% - 12px);
}

.execute-dropdown-with-external-button .input-group-addon {
  height: 19px;
  width: 30px;
  color: $colorBlue;
}

.execute-dropdown-with-external-button .form-control {
  border-radius: 4px 0 0 4px;
}

.highlight {
  padding: 5px;

  @media all and (max-width: $screen-sm-max) {
    padding: 5px 5px 5px;
  }

  &.bg-white {
    background: $colorWhite;
  }

  &.wrap-border {
    border-top: 1px solid $colorGrey-dark;
    border-bottom: 1px solid $colorGrey-dark;
  }

  &.wrap-border-full {
    border: 1px solid $colorGrey-dark;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  &.no-padding {
    padding: 0;
  }
}

.ag-react-container {
  .react-contextmenu-wrapper,
  .no-approval-list__row {
    height: 19px;
  }
}

.load-wrapper {
  position: relative;

  .loading {
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 3000;
    opacity: .33;
    min-height: 60px;
    width: 100%;
    height: 100%;
  }

  .fa-spinner{
    position: absolute;
    left: calc(50% - 23px);
    top: calc(43%);
    z-index: 3001;
  }

  .bl {
    .fa-spinner {
      position: initial;
    }
  }

  &.absolute-loading {
    .loading {
      position: absolute;
      left: 0;
      top: 0;
      background: #fff;
      z-index: 3000;
      opacity: .33;
      min-height: 60px;
      right: 0;
      bottom: 0;
    }

    .fa-spinner{
      position: fixed;
      top: auto;
      bottom: calc(43%);
    }
  }
}

.ag-theme-fresh {
  .ag-row.active {
    background: #ddd;
  }
  .ag-cell {
    width: 100%;
    line-height: 1.4 !important;
  }

  .ag-cell-not-inline-editing {
    padding: 2px !important;
  }

  .ag-cell.ag-cell-inline-editing {
    border-right: 1.5px solid darkgrey !important;
  }

  .ag-cell-inline-editing {
    height: 100% !important;
  }
}

.ag-cell-with-height {
  height: 100% !important;
}

.modal-backdrop.in.unclosable {
  opacity: 1;
  background-color: #fff;
}

a.input-group-addon {
  color: $colorBlue;
}

.full-height.next-actions-table {
  height: auto!important;
}

.flexWrapper {
  display: flex !important;
  flex-direction: column;

  .tabsContainer {
    flex: 1;
    padding-bottom: 42px;

    .tabContainer {
      flex: 1;

      $height: 0px;

      @while $height < 720 {
        $min-height: $height + 630;
        $el-height: $height + 340;
        @media screen and (min-height: $min-height) {
          max-height: $el-height;
        }
        $height: $height + 10;
      }
    }
  }

  .flexItem {
    flex: 1;
  }
}

.wrap-group {
  height: 42px;
}

.CompanyBuyerProjectsTable {
  .ag-row {
    &.disable {
      opacity: 0.5;
    }
  }
}

.addCategoryBtn {
  position: absolute;
  z-index: 99;
  border: none;
  background: #f5f5f5;
  top: 18px;
  left: 1px;
  height: 24px;
  width: 30px;
}

.addActiveProjectBtn {
  position: absolute;
  z-index: 99;
  border: none;
  background: #f5f5f5;
  top: 50%;
  left: 6px;
  height: 24px;
  width: 30px;
  margin-top: 16px;
}

.addActiveProjectBtn-ceo {
  position: absolute;
  z-index: 99;
  border: none;
  top: calc(8% + 2px);
  background: transparent;
  height: 24px;
  width: 30px;
}

.display-block {
  display: block;
}

.company-buyer-profile-dropdown {
  white-space: nowrap;

  &>span {
    &+span {
      padding-left: 1em;
    }
  }
}

.project-universe {
  padding-top: 10px;

  .table-main {
    margin-top: 0;
  }
}

.row.approvals {
  margin-bottom: 10px;
}

.project__containertab-pane.flex-through .container {
  max-height: 100%;
  display: flex;

  .container-fluid.flexWrapper {
    margin-left: 0;
    margin-right: 0;

    .table-main.table-responsive.flexItem.scrollable {
      margin-bottom: 10px;
    }
  }
}

.position-absolute {
  position: absolute;

  &.text-right {
    right: 10px;
  }
}

.position-relative {
  position: relative;
}

.buyer-profile-dropdown-span {
  min-width: 250px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;

  &>span:nth-child(1) {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &>span:nth-child(2) {
    margin-left: 1em;
    text-align: right;
  }
}

.react-datepicker {
  &__day, &__day-name  {
    font-size: 12.8px !important;
    line-height: 27.2px !important;
    width: 27px !important;
  }

  &__current-month {
    font-size: 15px !important;
    line-height: 21.5px !important;
  }

  &__month-select, &__year-dropdown-container {
    font-size: 12px;
    line-height: normal;
  }
}

.full-width .react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

div.react-datepicker-popper {
  z-index: 1050;
}

.react-datepicker__tether-target-attached-right.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle,
.react-datepicker__tether-target-attached-right.react-datepicker__tether-element-attached-top .react-datepicker__triangle {
  margin-left: 90px;
}

.ag-react-container {
  .react-datepicker__input-container {
    width: 100%;
    input {
      border: none;
    }
  }
}

.buyer-fund {
  .react-datepicker__input-container {
    input[type='text'] {
      height: 23px;
    }
  }
}

.country-w100p {
  div.input-group {
    width: 100%;
  }
}

.display-md-block {
  @media all and (max-width: $screen-md-max) {
    display: block;
  }
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.item-email {
  padding: 0 10px 5px 10px;
}

.ag-cell-edit-input {
  border: 1px solid red;
}

.add-on-project {
  width: 100%;
  .ag-header-cell {
    text-align: left;
  }
  .ag-header-cell-label {
    justify-content: flex-start;
    align-items: baseline;
  }

  .ag-cell-inline-editing {
    input.form-control {
      margin-top: 2px;
      margin-left: 2px;
      margin-right: 2px;
      border-radius: 2px;
      box-shadow: none;
      border: 1px solid #ccc;
      width: 98%;
    }
  }

  .ag-react-container {
    div.form-group {
      width: 98%;
    }
  }

  &-panel {
    position: relative;
    min-height: 500px;
    height: 100%;

    .project {
      height: 180px;
    }
  }

  &-section {
    overflow-y: auto;
    height: 0;
    min-height: 100%;
  }
}

.status--panel {
  display: flex;
  margin-top: 15px;
}

.status {
  height: 80px;
  display: flex;

  &__start, &__end {
    width: 100px;
  }

  &__start {
    flex-direction: column-reverse;
  }

  &__mid {
    width: 180px;
    flex-direction: column;
  }

  &__end {
    flex-direction: column-reverse;
  }

  &__up, &__down {
    width: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
  }
}

.alert-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0;
  font-weight: bold;
  color: red;
  font-size: 14px;
}

.col-industries {
  width: 13%;
}

.col-users {
  width: 9%;
}

.col-approvals {
  width: 78%;
}

.wysiwygView {
  width: 100%;
  border: 0;
}

.wysiwygContainer,
.wysiwygContainer .clearfix,
.wysiwygWrap,
.modal-content.h100p .wysiwyg {
  height: 100%;
}

.modal-content.h100p .ql-editor {
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-content.h100p .quill {
  height: calc(100% - 42px - 15px); // 42px is height of toolbar, 15px height of label
}

.ql-editor p {
  font-size: 12px;
}

.ag-header-cell {
  padding: 0 !important;
}

.global-spinner {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1051;
}

// Idle modal
.modal-activity-detector {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
}

.modal-activity-detector:before {
  content: '';
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background-color: #000;
  opacity: .5;
}

.modal-activity-detector > .card.card-container {
  z-index: 2001;
}

.modal-activity-detector .caption-activity-detector {
  margin-top: 25px;
  color: #fff;
}

.modal-activity-detector + * {
  filter: blur(5px);
}

.new-add-on-project .table-approval-list {
  height: 180px;
  white-space: normal;
}
