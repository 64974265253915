div.left-menu {
	border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
	min-height: 80px;
	position: relative;
	max-height: calc(100% - 2px);
	overflow-y: auto;
	overflow-x: hidden;
	ul {
		list-style: none;
		padding: 5px 0;
		margin: 2px 0 0;
	    text-align: left;

		li {
			a {
				display: block;
				padding: 3px 10px;
				clear: both;
				font-weight: 400;
				line-height: 1.42857143;
    			white-space: nowrap;
				text-decoration: none;
				overflow-x: hidden;
				text-overflow: ellipsis;
			}
			a:hover {
				color: #262626;
				text-decoration: none;
				background-color: #f5f5f5;
			}
			a:focus {
				color: #23527c;
				text-decoration: none;
			}
			a.active {
				background-color: #23527c;
				color: white;
			}
		}
	}
}

div.body-grid {
	table {
		border: 1px solid rgba(0,0,0,.15);
    	border-radius: 4px;
      td {
        cursor: pointer;
      }
	}
}

.approval-body {
	display: flex;
}

.approval-body__left-side {
	max-height: 100%;
}

.approval-body__right-side {
	position: relative;
}
