.tag-page {
  padding: 20px;

  .tag-header {
    font-size: 16px;
  }

  .spinner--icon {
    z-index: 1;
  }
}

.tag-table {
  th,
  td {
    padding: 5px 10px !important;
  }
  th {
    font-size: larger;
  }
}

.tag-panel {
  position: relative;
  height: calc(100% - 60px);
  overflow: auto;
  .tag-body {
    thead {
      visibility: collapse;
    }
  }
}

.tag-row {
  display: flex;
  > div {
    flex: 1;
  }
  label {
    min-height: unset;
    input[type='checkbox'] {
      margin-top: 0px;
    }
    i.fa {
      margin-left: 5px;
    }
  }
  ul {
    list-style: none;
    padding-left: 20px;
    div.checkbox {
      margin: 0 0 10px 0;
    }
  }
  tr {
    span {
      margin: 0 5px;
    }
  }
}

.tag-list {
  min-height: 250px;
  max-height: 500px;
  overflow-y: auto;
}

.business-model {
  margin-bottom: 8px;
  padding-left: 5px;
  display: flex;
  align-items: center;
  background-color: rgb(230, 242, 255);

  .checkbox {
    min-width: 200px;
  }
}

.industry-tags {
  padding-left: 5px;
  background-color: rgb(230, 253, 224);

  span {
    font-size: 13px;
  }
}

.tag-error {
  font-size: 13px;
  font-weight: 600;
  color: #f00;
}

#tagsManagementPopup {
  .modal-dialog {
    width: 780px;
  }
}
