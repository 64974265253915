.flex-through {
  display: flex !important;
  flex-direction: column;
  flex: 1;
}

.scrollable {
  overflow-y: auto;
  margin-bottom: 10px;
}

.dn-panel {
  float: left;
  overflow: hidden;
  height: 100%;
  .switcher {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;
    color: white;
    z-index: 2;
    text-align: center;
    padding-top: 4px;
  }
  .react-resizable-handle {
    display: block;
    position: absolute;
    background: $colorBlue;
    top: 0;
    bottom: 0;
    right: 0;
    width: 32px;
    height: 100%;
    border-radius: 3px;
    z-index: 1;
    &:after {
      content: 'Deal Notes';
      color: white;
      display: inline-block;
      font-size: 16px;
      width: 94px;
      transform: translate(0, 200px);
      -ms-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      transform-origin: 60px 56px;
    }
  }
  .content {
    height: 100%;
    width: calc(100% - 32px);
    overflow: auto;
  }
  &.note-close {
    width: 32px !important;
  }
}

.table-target {
  height: calc(100% - 300px);
  overflow-y: auto;
  background-color: #fff;
  position: relative;
}

.client-summary-report {
  color: $colorBlue;
  margin: 0 0 0 10px;
  padding: 0;
  font-weight: bold;
  text-decoration: underline;
}
.client-summary-report:hover {
  cursor: pointer;
}

.buyer-high-status:hover {
  cursor: pointer;
}

