$tableHover: #d1edff;

.overflow-scroll {
  overflow: hidden;
  height: 160px;
  overflow-y: scroll;
}

.user-table {
  overflow-x: auto;
  overflow-y: auto;
  margin-bottom: 20px;
  flex-grow: 1;
  height: 100%;

  tbody tr{

    &:hover {
      cursor: pointer;

      td {
        background-color: $colorGrey-dark;
      }
    }
  }
}

.bold-table-row {
  td {
    font-weight: bold;
  }
}

.table-dates-flex {
  display: flex;
  align-items: stretch;
  position: relative;

  .table-responsive {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: auto;

    .table-dates {
      position: absolute;
    }
  }
}
table.table thead tr th {
  background-color: $tablesTheadColor;
  padding: $tablesTheadCellpaddings;
}

.table-dates {
  margin-bottom: 0;
  table-layout: fixed;

  thead, tbody {
    display: block;

    tr {
      display: block;
      cursor: pointer;

      &.unactive {
        cursor: default;
      }

      &:hover {
        td {
          background: $colorGrey;
        }
      }
    }
  }

  table {
    width: 100%;
  }

  thead tr th {
    width: 8%;
    border: none;
  }

  tbody tr td {
    border-left: none;
  }

  tbody {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 158px;

    @media all and (max-width: $screen-md-max) {
      max-height: 168px;
    }
  }

  thead {
    th {
      &:nth-child(1) {
        width: 70px;
      }

      &:nth-child(2) {
        width: 120px;
      }

      &:nth-child(3) {
        min-width: 290px;
        width: calc(100% - 70px - 120px);
      }
    }
  }

  tbody {
    td {
      &:nth-child(1) {
        width: 70px;

        > div {
          width: 70px;
          overflow: hidden;
        }
      }
      &:nth-child(2) {
        width: 120px;

        > div {
          width: 115px;
          overflow: hidden;
        }
      }

      &:nth-child(3) {
        min-width: 390px;
        width: calc(100% - 70px - 120px);
        max-width: calc(100% - 70px - 120px);

        > div {
          min-width: 380px;
          width: calc(100% - 10px);
          max-width: 700px;
          overflow: hidden;
        }
      }
      .react-contextmenu-wrapper {
        text-overflow: ellipsis;
        overflow: hidden;
      }

    }
  }

  tbody tr td,
  thead tr th {
    display: inline-block;
  }
  tbody td[colspan]{
    display: block;
    padding: 3px;
    text-align: center;
  }
}

.flexItem.table-dates tbody.flexItem {
  max-height: unset;
}

table.table {
  width: 100%;

  thead {
    background: $colorGray-light;
  }

  tr {
    th, td {
      padding: 5px;
      border-bottom: none;
    }

    th {
      background: $colorGrey-dark;
      font-size: 12px;
      border-bottom: none;
      white-space: nowrap;
    }

    td {
      padding: 1px;
      font-size: 12px;
      white-space: nowrap;
    }

    input:not(.bounded).form-control {
      border: 0;
      box-shadow: none;
    }
  }

  &.t-actions {
    td {
      white-space: normal;
      padding: 2px;
      vertical-align: middle;
      font-size: 11px;
      line-height: 1.1;

      &:first-child {
        padding-left: 5px;
      }

      @media all and (max-width: $screen-xxl-max) {
        max-width: 193px;
      }
    }

    &.table-hover {
      tr:hover td {
        background-color: $tableHover;
      }
    }
  }

  &.table-align-middle {
    td {
      vertical-align: middle;
    }
  }

  .fa-2x {
    font-size: 12px;
    padding: 0 7px;
    cursor: pointer;
  }

  &.t-top {
    .plus-icon {

      span {
        vertical-align: middle;
      }

      .fa-2x {
        font-size: 12px;
        margin-right: 10px;
        position: relative;
        cursor: pointer;
        vertical-align: middle;
      }
    }
  }

  .table-bordered > thead > tr > th,
  .table-bordered > thead > tr > td {
    border-bottom-width: 1px;
  }
}

.t-buyer {
  border: 1px solid $colorGrey-dark !important;
  margin-bottom: 20px !important;

  tr {
    th {

      .fa {
        padding-right: 7px !important;
      }
    }

    td {
      background: $colorWhite;
    }
  }
}

.panel {
  table tr th {
      border: 1px solid $colorGrey;

    &:first-child {
      border-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
  }
}

.project-table {
  table.table {

    th, td {
      width: 8%;
      padding: 0;
      text-align: center;
    }

    &.add-on {
      th, td {
        width: 9%;
      }
    }
  }
}

.top_side .table-dates {
  @media all and (max-width: $screen-sm-max) {
    td {
      font-size: 12px;
    }
  }
}

@media all and (max-width: $screen-sm-max) {
  .table-responsive {
    border: none;

    &.sm-p0 {
      padding: 0;
    }

    &.sm-p15 {
      padding: 15px;
    }
  }
}

.fa-caret-down, .fa-caret-up {
  cursor: pointer;
}

.BrowseTable {
  .ag-scrolls .ag-row {
    &:hover {
      background: $colorGrey-dark;
    }
  }
}

.ag-approval .ag-floating-bottom-container {
  .ag-row {
    &:nth-child(2) {
      border-top: 1px dotted #ccc !important;
    }

    .ag-cell:not(:first-child) {
      font-weight: normal;
    }
  }
}

.ag-theme-fresh div.ag-header,
.ag-theme-fresh .ag-ltr .ag-header-cell {
  background: #f5f5f5;
  font-size: 12px;
  color: $text-color;
  font-weight: 600;
}

.ag-header-cell-label {
  justify-content: center;
  width: 100% !important;
  padding: 0 2px;

  .ag-header-icon {
    margin-top: -2px !important;
  }
}

.ag-theme-fresh div.ag-header,
.ag-theme-fresh div.ag-root,
div .ag-theme-fresh .ag-ltr .ag-header-cell {
  border-color: #ddd;
}

div.ag-theme-fresh {
  font-size: 12px;
}

.ag-header-cell-sortable {
  cursor: pointer;
}

.table .inner-table-input {
  padding-left: 0;
  padding-right: 0;
}

.contact__main  .accordion-middle-side .table td,
.contact__main .accordion-middle-side .table td input {
  text-align: left;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.contact__main  .accordion-middle-side .table td {
  padding-left: 2px;
  padding-right: 2px;
}
.CompanyBuyerLeadsTable {
  position: relative;
  .title {
    position: absolute;
    top: calc(8% - 18px);
  }
  .ag-theme-fresh {
    position: absolute;
    top: 8%;
    height: 92% !important;
    width: 100%;
  }
  .autocompleteWrapper {
    .input-group {
      width: 100%;

      input {
        border: none;
        width: 100%;
        outline: none;
        background: transparent;
      }
    }
  }
}

.approval-flex-grid {
  display: flex;
  font-weight: bold;
  border-top: 2px solid #000;

  .short-col {
    padding: 3px;
    width: 90px;
  }

  .long-col {
    padding: 3px;
    width: 185px;
  }
}

#slide-7 {
  overflow-y: auto;
  height: 100%;
  > div {
    min-height: 300px;
  }
}

#buy-slide-1 {
  overflow-y: hidden;

  .deal-notes {
    overflow: auto;
  }
}

.tab-project {
  &--section {
    overflow-y: auto;
    height: 100%;
    > div {
      min-height: 300px;
    }
  }
}

.criteria {
  &--section {
    overflow-y: auto;
    height: 100%;
  }
  &--panel {
    height: 100%;
  }
}

/*hacky: To show scrollbar on firefox*/
@-moz-document url-prefix() {
  #slide-7 {
    height: 0;
    min-height: 100%;
  }
  #buy-slide-1 {
    height: 0;
    min-height: 100%;
  }
  .tab-project--section {
    height: 0;
    min-height: 100%;
  }
  .criteria--section {
    height: 0;
    min-height: 100%;
  }
}

/*hacky: To show scrollbar on edge*/
@supports (-ms-ime-align: auto) {
  #slide-7 {
    height: 0;
    min-height: 100%;
  }
  #buy-slide-1 {
    height: 0;
    min-height: 100%;
  }
  .tab-project--section {
    height: 0;
    min-height: 100%;
  }
  .criteria--section {
    height: 0;
    min-height: 100%;
  }
}

.panel table.table,
table.table {
  thead {
    th {
      border-left: 0;
      border-right: 1px solid #ddd;
      &:last-child {
        border-right-width: 0;
      }
    }
  }
}


table.table.flexItem{
  height: 100%;
}

table.table.table-dates.flexItem,
table.table.table-dates.empty-table.flexItem {
  height: 100%;


  thead,
  tbody.flexItem {
    display: flex;
    flex-direction: column;

    tr {
      display: flex;
      flex-wrap: nowrap;
      min-height: 1.5em;
    }
  }
}

.BrowseTable-controlsWrap {
  z-index: 1;
}

.table-comparables.table tbody tr td {
  padding: 0.25em 0;
}

.links-table-wrap {
  height: 100px;
  overflow: auto;
  border: 0.05px solid $colorGrey-dark;

  .links-table {
    width: 100%;
    border-collapse: separate;

    th {
      position: sticky;
      top: 0;
      padding: 4px 0 4px 2px;
      border: 0.1px solid $colorGrey-dark;
      background-color: $colorGray-light;
    }

    .link-cell {
      max-width: 290px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .link-cell:hover {
      cursor: default;
    }

    td {
      padding: 4px 0 4px 2px;
      &:not(:first-child) {
        border-left: 0.1px solid $colorGrey-dark;
      }
      &:last-child {
        border-right: 0.1px solid $colorGrey-dark;
      }
    }
  }
}

.double-column {
  text-align: center;
}

.double-column-cell {
  width: min-content;
  text-align: center;
}

.trade-groups-table {
  height: 700px;

  & .ag-body-horizontal-scroll {
    display: none;
  }
}

.ag-header-group-cell-label {
  justify-content: center;
}

.one-row-table, .events-table {
  & thead {
    background-color: $tablesTheadColor;
  }

  & tbody {
    background-color: $colorWhite;
  }
}

.ag-body-viewport {
  background-color: #f6f6f6;
}

.trade-show-companies-table {
  height: 450px;
  border-radius: 2px;
  .ag-header-cell-label {
    justify-content: flex-start;
  }
  .ag-layout-normal > .ag-body-viewport.ag-layout-normal {
    overflow-y: overlay;
  }
}

.trade-show-top-table {
  background-color: #fff;
  border-radius: 2px;
  overflow-y: auto;
  overflow-x: hidden !important;
  height: 137px !important;
  & tbody {
    background-color: $colorWhite;
  }

  & thead {
    position: sticky;
    top: 0;
  }

  & .ag-header-cell-label {
    justify-content: flex-start;
  }
}

.events-table-container {
  max-height: 150px;
  border-radius: 2px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.events-table {
  margin-bottom: 0;
}

.events-table th {
  position: sticky;
  top: 0;
}

.events-table tbody tr:hover {
  cursor: pointer;
  background-color: $tablesTheadColor;
}
