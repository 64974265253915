@import "variables";

.trade-show-page {
  display: flex;
  flex-direction: column;
  background-color: $dark-blue;
  height: 100%;
  overflow-y: auto;
}

.trade-show-top-table-block {
  margin-top: 20px;
}

.required-error-block {
  border: 2px solid $error-color;
}

.save-button-container {
  position: absolute;
  bottom: 20px;
  right: 50px;
}

.year-filter, .year-filter-block {
  margin-bottom: 5px;
}

.year-filter-block {
  background-color: $colorWhite;
  width: 50px;
  padding-left: 5px;
}

.industries-table-header {
  display: flex;
  justify-content: flex-start;
  padding: 2px 0;

  & .icon-wrapper i {
    padding: 0 4px 0 0;
  }
}

.trade-show-footer {
  height: 100%;
  position: relative;
}

