.bg-white {
    .spinner {
        &--curtain{
            background: white;
        }
    }
}

div.na-cb-sel-box {
    ul {
        width: 300px !important;
    }
}

.approval-main-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .top-section {
        margin-bottom: 10px;
        min-height: 80px;
    }
}

.online-approval-list-content{
  padding: 20px 10px;

  .priority-codes,
  .buyer-name {
    margin-top: 0;
  }

  .buyer-name,
  .approval-list-label,
  .priority-codes {
    font-weight: bold;
  }

  .buyer-name {
    text-decoration: underline;
    color: #23527c;
  }

  .approval-list-label {
    font-style: italic;
  }

  .img-logo {
    height: 30px;
  }

  .right-part {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    text-align: right;
  }

  .right-part > ul {
    width: 100%;
    display: block;
  }

  .bordered {
    text-align: center;
    border: 1px solid #000;
    padding: 10px;
  }

  .table-generate-approval-list {
    width: 100%;
    height: 100%;
    max-height: calc(100% - 370px);
  }

  .table-generate-approval-list.table-online {
    max-height: calc(100% - 270px);
  }

  .table-generate-approval-list .ag-theme-fresh .ag-ltr .ag-cell {
    border: none;
  }

  .table-generate-approval-list .multiline-text {
    display: flex;
    -webkit-line-clamp: 7;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }

  .table-generate-approval-list .ag-header-cell.text-left .ag-header-cell-label {
    justify-content: left;
  }

  .table-generate-approval-list .ag-theme-fresh div.ag-header,
  .table-generate-approval-list .ag-theme-fresh .ag-ltr .ag-header-cell {
    background: none;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 4px solid #000;
  }

  .table-generate-approval-list .ag-theme-fresh .ag-root{
    border: none;
  }

  .table-generate-approval-list .ag-theme-fresh {
    width: 100%;
  }

  .table-approval-recipients {
    height: 140px;
    display: flex;
  }

  .table-approval-recipients .ag-theme-fresh {
    width: 100%;
    margin-right: 12px;
  }

  .table-approval-recipients .ag-header-cell-label {
    justify-content: flex-start;
    align-items: baseline;
  }

  .table-approval-recipients .ag-header-button {
    border: none;
    background-color: inherit;
  }

  .table-approval-recipients .ag-cell-inline-editing input.form-control {
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 2px;
    box-shadow: none;
    border: 1px solid #ccc;
    width: 98%;
  }
}

.approval-body{
    flex: 1;
    max-height: calc(100% - 250px);
}

.approval__footer {
    margin-top: 10px;
    margin-bottom: 10px;
}

.ap-in-editor {
  font-weight: bold !important;
  border: none !important;
  &--error {
    color: red;
  }
}

.readonly-datepicker.form-control[readonly]{
  color: inherit;
  background-color: inherit;
  width: 100px;
}
