.controls {
	.btn {
		font-size: 12px;
	}

	.dropdown-toggle {
		.caret {
			margin-left: .5em;
		}
	}

	.dropdown-menu {
		a {
			text-decoration: none;
		}
	}

	&__toggle {
		.btn {
			@media screen and (max-width: $screen-sm-max) {
				width: 100%;
				margin-top: 1em;
			}			
		}
	}
}

.controls-search {

	&__input {
		margin-bottom: 4px;
	}

	&__link {
		text-decoration: none;
		color: #000;
		font-size: 12px;
	}
}