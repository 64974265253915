#root {
	.ag-theme-fresh {
		height: 100%;
	}
	.BrowseTable {
		.ag-theme-fresh {
			flex: 1;
			position: absolute;
			top: 38px;
			bottom: 0;
			width: 100%;
			height: auto;
		}
	}
}
.BrowseTable {
	flex-grow: 1;
	max-width: 100%;
	flex: 1;
	overflow-x: hidden;
	margin-right: 10px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.BrowseTable-controlsWrap {

	@media screen and (max-width: $screen-xs-max) {
		margin-top: 18px;
		margin-bottom: 18px;
	}
}

.BrowseTable-controls {

	@media screen and (min-width: $screen-xs-min) {
		margin-top: 18px;
		margin-bottom: 18px;
	}

	font-size: 12px;

	.btn {
		text-decoration: none;
		font-size: 12px;

		.fa {
			margin-right: 4px;
		}

		&-direct {

			.fa {
				margin-right: 0;
				line-height: 16px;
			}
		}
	}

	&--main {
		@media screen and (max-width: $screen-xs-max) {
			margin-bottom: 12px;
		}
	}

	&--buttons {
		@media screen and (min-width: $screen-xs-min) {
			text-align: right;
		}

		.btn {
			margin-left: 12px;

			&:first-child {
				margin-left: 0;
			}

		}
	}
	.dropdown {
		display: inline-block;
	}
}

.BrowseTable-control {
	text-decoration: none;
	border-bottom: 1px dotted;
	margin-right: 12px;

	&:hover {
		border-bottom-color: transparent;
	}
}

.BrowseTable-results {
	margin-left: 12px;
}

.BrowseTable-message {
	font-size: 12px;
}

.BrowseTable-content {
	overflow-x: auto;
}
.approvedWrapper {
	text-align: center;

	i {
		color: #62BE62;

		&.text-danger,
		&.fa-close,
		&.fa-times {
			color: #F00;
		}
	}
}
.colorPositive {
	color:#006600;
}
.colorNegative {
	color:#ff0000;
}