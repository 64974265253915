.top_side {
  margin-top: 10px;

  .top-row {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: stretch;
  }

  .col-table {
    //table {}
  }

  form {
    .information {

      @media all and (max-width: $screen-xs-max) {
        &:first-child {
          padding-top:10px;
        }
      }

      @media all and (max-width: $screen-xs-max) {
        .highlight {
          margin-bottom: 0;
          padding-bottom: 0;
          padding-top: 0;
        }
      }

      .city-index {
        .input-group-addon {

          i {
            &:before {
              left:5px;
            }
          }
        }

        @media all and (min-width: $screen-xxl-min ) {
          padding-left: 10px;
        }
      }

      .form-group {
        overflow: hidden;
        margin-bottom: 5px;

        @media only screen and (min-width: $screen-xxl-min) {

          &.abbr.mb0 {
            width: 8.33333333%;
            float: left;
          }
        }
      }

      @media only screen and (max-width: $screen-xxl-max) {
        .city-index {
          margin-top: 6px;
        }
      }

      .f-city {
        margin-top: 6px;
        margin-right: 20px;
      }

      #f-city {
        margin-bottom: 5px;
      }

      #f-abbr {
        &.col-xxl-11 {
          width: 91.66666667%;
        }

        .col-xxl-1 {
          width: 8.33333333% !important;
        }
      }

      .control-label {
        padding-top: 7px;
        text-align: left;
        height: 25px;
        padding-left: 0;
        padding-right: 0;

        &.abbr {
          padding-right: 29px;
        }
      }

      select {
        margin-right: 20px;
        padding: 0 0 0 5px;
      }
    }
  }
}

.input-group-addon {
  padding: 3px 12px;
}
