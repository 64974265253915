.windward-report-fields {
  padding: 10px;
  min-height: 200px;
  .windward-report-fields__form-content {
    width: 50%;
    padding-right: 10px;
  }
  label {
    font-weight: normal;
  }
}
