.search-result {
	&__line {
		display: flex;
		flex-wrap: nowrap;
		cursor: pointer;
		text-decoration: none;
		padding: 2px;
		font-size: 10px;
		background-color: transparent;
		background-color: #fff;
		border: 1px solid #ddd;
		color: black;
		margin-bottom: -1px;
		h4 {
			font-size: 11px;
			color: #000;
			margin: 0 0 4px 0;
			font-weight: 700;
		}
		.green {
			color: #00A000;
		}
		.purple {
			color: #A000BA;
		}
		.blue {
			color: #28428e;
		}
		.red {
			color: red;
		}
		.na-title {
			color: #aaa;
			width: 75px;
			display: inline-block;
		}
	}

	&__line:hover {
		background-color: #f0f6f8;
		text-decoration: none;
		color: black;
	}
	&__line:focus {
		text-decoration: none;
		color: black;
	}
	&__line:first-child {
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
	}
	&__line:last-child {
		margin-bottom: 0;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
	}
	&__item {
		p:last-child {
			margin-bottom: 0;
		}
		p {
			font-size: 9px;
		}
	}
	&__item-name {
		width: 15%;
		word-wrap: break-word;
		padding: 0 5px 0 0;
	}
	&__item-location {
		width: 9%;
		word-wrap: break-word;
		padding: 0 5px;
	}
	&__item-contact {
		width: 9%;
		padding: 0 5px;
		h4 {
			font-size: 10px;
		}
	}
	&__item-target {
		width: 11%;
		padding: 0 5px;
		color: #28428e;
		font-size: 8px;
		strong {
			font-size: 10px;
		}
	}
	&__item-commentary {
		width: 15%;
		padding: 0 5px;
		color: #23527c;
	}
	&__item-account {
		width: 8%;
		padding: 0 5px;
	}
	&__item-activity {
		width: 15%;
		padding: 0 5px;
	}
	&__item-category {
		width: 7%;
		padding: 0 0 0 5px;
	}
	&__item-website {
		width: 12%;
		padding: 0 0 0 5px;
	}


	&__pitem-name {
		width: 16%;
		word-wrap: break-word;
		padding: 0 5px 0 0;
	}
	&__pitem-contact {
		width: 18%;
		padding: 0 5px;
	}
	&__pitem-exec {
		width: 10%;
		word-wrap: break-word;
		padding: 0 5px;
		color: #00a000;
		.exec {
			font-size: 8px;
		}
	}
	&__pitem-exec-large {
		width: 12%;
		word-wrap: break-word;
		padding: 0 5px;
		color: #00a000;
	}
	&__pitem-category {
		width: 10%;
		padding: 0 0 0 5px;
	}
	&__pitem-activity {
		width: 24%;
		padding: 0 5px;
	}
}

.list-group.search-items {
	min-width: 990px;
	overflow: hidden;
}
