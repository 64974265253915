@mixin button() {
	padding-top: 4px;
}

.search {
	margin-top: 24px;
	margin-bottom: 24px;

	&__form {
		.btn {
			.fa {
				margin-right: 4px;
			}

			height: 26px;
		}
		.search-text{
			height: 26px;
		}
	}

	&__dropdown {
		a {
			text-decoration: none;
		}

		.btn {
			@include button();

			.caret {
				margin-left: 8px;
			}
		}

		.dropdown-menu > li > a {
			font-size: 14px;
			padding-left: 14px;
			padding-right: 14px;
		}
	}
}


.search-checkbox {
	font-weight: 400;
	margin-top: 8px;
	font-size: 10px;
}