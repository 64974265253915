/* gradient */
@mixin gradient($startColor, $stopColor) {
  background: linear-gradient(to top, $startColor 0%, $stopColor 100%);
}

/* placeholder */
@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &::-ms-input-placeholder {
    color: $color;
  }
}

/* ... */
%text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* font size */
@for $i from 1 through 40 {
  .font-size-#{$i} {
    font-size: ($i*1px) !important;
  }
}

/* paddings and margins */
@for $i from 0 through 100 {
  .m#{$i} {
    margin: ($i*1px) !important;
  }

  .mt#{$i} {
    margin-top: ($i*1px) !important;
  }

  .mr#{$i} {
    margin-right: ($i*1px) !important;
  }

  .mb#{$i} {
    margin-bottom: ($i*1px) !important;
  }

  .ml#{$i} {
    margin-left: ($i*1px) !important;
  }

  .p#{$i} {
    padding: ($i*1px) !important;
  }

  .pt#{$i} {
    padding-top: ($i*1px) !important;
  }

  .pr#{$i} {
    padding-right: ($i*1px) !important;
  }

  .pb#{$i} {
    padding-bottom: ($i*1px) !important;
  }

  .pl#{$i} {
    padding-left: ($i*1px) !important;
  }
}

/* width */
@for $i from 1 through 100 {
  .w#{$i}p {
    width: ($i*1%) !important;
  }
}

@mixin triangle($width, $dir, $color) {
  @if $dir == top {
    width: 0;
    height: 0;
    border-left: $width/2 solid transparent;
    border-right: $width/2 solid transparent;
    border-bottom: $width/2 solid $color;
  } @else if $dir == bottom {
    width: 0;
    height: 0;
    border-left: $width/2 solid transparent;
    border-right: $width/2 solid transparent;
    border-top: $width/2 solid $color;
  } @else if $dir == right {
    width: 0;
    height: 0;
    border-top: $width solid transparent;
    border-bottom: $width solid transparent;
    border-left: $width solid $color;
  } @else if $dir == left {
    width: 0;
    height: 0;
    border-top: $width solid transparent;
    border-bottom: $width solid transparent;
    border-right: $width solid $color;
  }
}

