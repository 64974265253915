.leads-title {
  margin-top: 10px;
  text-align: center;
  font-size: 18px;
}

.leads-settings {
  display: flex;
  flex-direction: column;
  min-width: 180px;
}

.leads-settings__sort-title,
.leads-settings__filters-title {
  font-weight: 700;
}

.leads-settings__filters-list {
  height: 100%;
  overflow: auto;
}

.highlighted-tail-cell {
  background-color: #e9827e;
}

.highlighted-tail-cell-legend {
  color: #e9827e;
}

.ag-theme-fresh .ag-row.grey-shaded-effect {
  background-color: $colorGrey-dark-500;
}

.grey-shaded-effect-legend {
  color: $colorGrey-dark-500;
}

.ag-theme-fresh .ag-row.blue-shaded-effect {
  background-color: #98c9f3;
}

.blue-shaded-effect-legend {
  color: #98c9f3;
}

.controls-right {
  margin-bottom: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.controls-right .form-hide-duplicates {
  display: inline-flex;
  align-items: center;
  margin-right: 6px;
}
