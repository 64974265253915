.project-header {
	margin-top: 5px;
	position: relative;

	label {
		font-size: 12px;
	}
}

.project-header-info {
	color: $white;
	font-size: 12px;
	margin-bottom: 4px;
}

.company-name-link {
	text-decoration: none;
	color: $white;

	&:hover {
		color: $hover-color !important;
	}
}

.company-name-link:visited {
	text-decoration: none;
	color: $white;
}
