.top-user-bg {
	position: relative;
	min-height: 40px;
	margin-bottom: 20px;
	margin-top: 10px
}

.mailing-history {
	text-align: right;
}

.btn-toolbar.backlog-userList-pull-right {
	text-align: right;

	.btn {
		float: none;
	}
}