.menuItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menuItem:hover {
  .menuItem-label {
    text-decoration: none;
  }
}

.menuItem-label {
  text-decoration: underline;
  color: #337ab7;
}

.btnIcon {
  padding: initial;
  background: transparent;
  border: 0;
}
