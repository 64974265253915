.wrap-next-actions {
    .wrap-sort-order {
        ul.sort-menu {
            li {
                i.fa-caret-down {
                    right: 23px;
                    font-size: 15px;
                    top: 3px;
                }
            }
        }
    }
}

div.sort-menu {
    padding:0; // for IE 11
    margin-left: 0;
    -webkit-padding-start: 0;

    &__item {
        background: $colorGrey-dark-80;
        margin-bottom: 2px;
        width: 100%;
        padding: 2px 10px;
        font-size: 12px;
        position: relative;
        user-select: none;

        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }

        i {
            position: absolute;

            &.fa-caret-down,
            &.fa-caret-up {
                right: 23px;
                font-size: 15px;
                top: 3px;
            }

            &.fa-times {
                right: 4px;
                top: 5px;
            }
        }
    }
}
