.main-modals {
	margin-top: 1em;
	position: relative;
}

.modal-container {
	max-width: 100%;

	.panel-heading {
		cursor: move;
	}

	.panel-body {
		padding: 0;
	}
}
