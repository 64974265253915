.fix-h-300 {
    height: 300px !important;
    overflow-y: auto;
}

.backlog-captions {
    display: flex;
    align-items: center;

    &-square {
        width: 14px;
        height: 14px;
        background: #000;
        margin: 0 5px 0 15px;
    }

    &-future {
        background: #008000;
    }

    &-past {
        background: #ff0000;
    }
}
