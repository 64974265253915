@mixin add-button() {
	font-size: 10px;
	padding-left: 10px;
	padding-right: 10px;

	.fa {
		margin-right: 0;
	}
}

@mixin dropdown() {
	.btn {
		height: 21px;
		font-size: 12px;
		line-height: 1;
	}

	.dropdown-menu {
		font-size: 12px;
	}

	.dropdown-menu > li > a {
		padding-left: 12px;
		padding-right: 12px;
		min-height: 24px;
	}
}

.BrowseControls {
	width: 250px;
	overflow: auto;
}

.BrowseControl {

	&-content {
		padding: 6px 12px;
		margin-top: 6px;
		margin-bottom: 6px;
		border: 1px solid transparent;

		label {
			display: flex;
			font-weight: 400;
			font-size: 12px;
		}

		.flex-form {
			display: flex;
			margin-top: 5px;

			.form-group {
				margin-right: 10px;
			}

			.flex1 {
				flex: 1;
			}
		}

		&--slider {
			border-color: #ddd;

			.form-group {
				margin-top: 10px;
				display: inline-block;

				@media screen and (max-width: $screen-xs-max) {
					width: auto;
				}
			}

			.form-control {
				@media screen and (min-width: $screen-xs-min) {
					width: 90px;
				}

				@media screen and (max-width: $screen-xs-max) {
					width: 80px;
				}
			}
		}

		&--add {
			border-color: #ddd;

			.btn {
				@include add-button();
			}

			em {
				font-size: 10px;
			}

			.form-control {
				@media screen and (max-width: $screen-xs-max) {
					display: inline-block;
					width: 80%
				}
			}
		}

		&--form {
			border-color: #ddd;

			.form-group {
				&--input {
					.form-control {
						width: 100%;
					}
				}

				&--add {
					.btn {
						@include add-button();
						margin-top: 16px;
					}
				}

				&--dropdown {
					@include dropdown();
				}

				&.inline-input-button {
					display: inline-flex;
					position: relative;
					width: 100%;
				}

				&.inline-input-button .btn {
					position: absolute;
					bottom: 0;
					right: -11px;
					width: 30px;
					height: 19px;
				}

				@media screen and (min-width: $screen-xs-min) {
					display: inline-block;
					vertical-align: top;
					margin-right: 16px;

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}

		&--activity {
			border-color: #ddd;

			.form-group {
				vertical-align: top;

				&--input {
					margin-right: 12px;
				}
			}

			.btn {
				@include add-button();
			}
		}

		&--select {
			@include dropdown();
		}

		&--checkbox {
			margin: 0;
			padding: 5px 12px;
		}

		.btn-group {
			a {
				text-decoration: none;
			}
		}
	}

	&-label {
		display: flex;
		align-items: center;
		align-content: center;
		font-weight: 400;

		input[type='checkbox'] {
			margin-right: 4px;
			margin-top: 0;
		}
	}
	.InputRange-label {
		display: none;
	}
}

.checkbox-wrap {

	label {
		font-weight: 400;
	}

	input[type='checkbox'] {
		margin-right: 4px;
	}
}

.form-wrap {
	border: 1px solid #ddd;
	padding: 12px;

	@media screen and (min-width: $screen-xs-min) {
		display: inline-block;
	}
}

.form-group {

	&--inline {

		.btn-group {
			width: 100%;

			.btn {
				width: 100%;
				text-align: left;
			}
		}
	}
}

.btn-disabled {
	pointer-events: none;
	opacity: .7;
}

.MultiselectItem {
	$this: &;
	color: #fff;
	background-color: $blue;
	cursor: pointer;
	min-height: 28px;
	line-height: 18px;
	padding-left: 12px;
	padding-right: 12px;
	margin-top: 8px;
	margin-bottom: 8px;
	border-radius: 2px;
	font-size: 12px;
	position: relative;
	display: flex;
	align-items: center;

	&.isActive {
		background-color: $red;
	}

	&-close {
		margin-top: 5px;
		visibility: hidden;
		position: absolute;
		top: 0px;
		right: 5px;

		@at-root #{$this}:hover & {
			visibility: visible;
		}
	}

	&.exclude {
		background-color: $red;
	}
}

.activityPanelBlock {
	position: absolute;
	z-index: 1111;
	background: #FFF;
	border: 1px solid #CCC;
	overflow: hidden;
	display: flex!important;

	.panelCategory {
		overflow: hidden;
		border-right: 1px solid #CCC;
		padding: 0 5px 0 0;
		margin: 5px 0;

		&:last-child {
			border-right: none;
		}

		h4 {
			font-size: 15px;
			padding-left: 5px;
			margin-top: 0;
			font-weight: bold;
		}

		.panelBlockContent {
			overflow: hidden;

			.panelGroup {
				float: left;
				padding: 0 5px;
				margin: 0;
				list-style: none;

				li {
					cursor: pointer;

					&:hover {
						text-shadow: 1px 0 0 #000;
					}
				}
			}
		}
	}
}

.select-range {
	.react-autosuggest__container {
		position: relative;
	}

	.react-autosuggest__input {
		height: 19px;
	}

	.react-autosuggest__input--focused {
		outline: none;
	}

	.react-autosuggest__suggestions-container {
		display: none;
	}

	.react-autosuggest__suggestions-container--open {
		display: block;
		position: absolute;
		min-height: 20px;
		max-height: 140px;
		overflow-y: auto;
		z-index: 2;
	}

	.react-autosuggest__suggestions-list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	.react-autosuggest__suggestion {
		cursor: pointer;
		padding: 0 5px;
	}

	.react-autosuggest__suggestion--highlighted {
		background-color: #ddd;
	}
}

.activity-range {
	margin-top: 1em;
	margin-bottom: 1em;
}

.activity-range-input {
	width: 8em;
}

.trade-show-autocomplete {
	display: inline;
}
