footer {
  background: $colorBlack;
  padding: 10px;
  width: 100%;

  .container {
    background: $colorBlack;
  }

  @media all and (max-width: $screen-sm-max) {

    .pull-right {
      float: none !important;
      margin: 0 -15px;
      text-align: center;
    }
  }

  .btns > * {
    margin-left: 5px;

    &:first-child {
      margin-left: 0;
    }
  }

  select {
    margin-right: 3px;
    height: 33px;
    border-radius: 4px;
  }

  .add-acc {
    max-height: $input-height;
  }
}