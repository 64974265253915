.dashboard-table-wrapper {
  border: 1px solid $colorGrey-dark-500;
  border-radius: $border-radius-large;
  overflow: hidden;
}

.dashboard-table {
  width: 100%;
}

.dashboard-table.dashboard-transactions th,
.dashboard-table.dashboard-companies th {
  width: 33.33%;
}

.fixed-layout {
  table-layout: fixed;
}

.pseudo-border {
  position: relative;
}

.pseudo-border::after {
  position: absolute;
  top: 10%;
  right: 0;
  height: 90%;
  border-right: 1px solid $colorGrey-dark-500;
  content:"";
}

.bg-black {
  background-color: $colorGrey-dark-900;
}

.border-left {
  border-left: 1px solid $colorGrey-dark-500;
}

.border-right {
  border-right: 1px solid $colorGrey-dark-500;
}

.border-bottom {
  border-bottom: 1px solid $colorGrey-dark-500;
}

.deals-by-state span {
  display: inline-block;
}

.deals-by-state .state-title {
  width: 24px;
}

.deals-by-state .state-dot {
  width: 80px;
  border-bottom: 2px dotted $colorGrey-dark-500;
}

.deals-by-state .state-stats {
  padding-left: 4px;
}

.black {
  color: $black;
}

.stats-description {
  font-size: 85%;
  margin-bottom: 0;
}