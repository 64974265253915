.wrap-create-mail, .wrap-mailing-backlog, .wrap-next-actions {
  h1 {
    font-size: 18px;
    margin-top: 10px;

    span {
      font-size: 12px;
      font-weight: 400;
    }
  }

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
  }

  .mail-history {
    text-decoration: none;
    width: 100%;
  }
  
  table {
    tbody {
      tr {
        cursor: pointer;

        &:hover {
          td {
            background: $colorGrey-dark-80;
          }
        }
      }
    }

    &.t-actions {
      margin-top: 20px;

      tr {
        cursor: default;

        &:hover {
          td {
            background: $colorWhite;
          }
        }
      }
    }
  }

  .wrap-sort-order, .wrap-filters {
    margin-bottom: 30px;

    input {
      vertical-align: text-bottom;
      margin-left: 10px;
    }

    label {
      font-weight: normal;
    }

    p {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 0;

      span {
        font-size: 12px;
        font-weight: normal;
      }
    }

    ul.sort-menu {
      padding:0; // for IE 11
      margin-left: 0;
      list-style-type: none;
      -webkit-padding-start: 0;

      li {
        background: $colorGrey-dark-80;
        margin-bottom: 2px;
        width: 100%;
        padding: 2px 10px;
        font-size: 12px;
        position: relative;

        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }

        i {
          position: absolute;

          &.fa-caret-up {
            right: 23px;
            font-size: 15px;
            top: 3px;
          }

          &.fa-times {
            right: 4px;
            top: 5px;
          }
        }
      }
    }

    .col-xs-4 {
      @media (max-width: 440px) {
        width: 50%;
      }
    }
  }
}
.mailHistory {
  display: flex;
  flex-direction: column;
  height: 100%;

  .top_side {
    margin: 0;
    padding: 10px 0;
  }

  .mailing-table-controls {
    margin: 10px 30px;
    display: flex;
  }

  .mailing-table-control-group {
    margin-right: 15px;
  }

  .mailing-table-control {
    margin-right: 5px;

    &__input {
      height: 21px;
      width: 60px;
    }
  }

  .pmHeader {
    .colHeader {
      span {
        float: left;
        margin-right: 5px;
      }
      strong {
        display: inline-block;
      }
    }
  }
  .main {
    flex: 1;
    margin-bottom: 42px;
    position: relative;

    > .container {
      position: relative;
      height: 100%;

      .MailingHistoryTable,
      .MailingCreateTable {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}

.MailingCreateTable {
  .ag-react-container {
    height: 100%;
    textarea {
      height: 100%;
    }
  }
}