._fullheight {
	height: 100%;
}

.page--project {
	padding-top: 1px;

	body {
		background-color: $bg-color;
		min-height: 100%;
		display: flex;
		@media screen and (min-width: $screen-md-min) {
			flex-direction: column;
		}

		.container,
		.container-fluid {
			height: 100%;
			max-height: 100%;
		}
		.container-fluid {
			@media screen and (min-width: $screen-md-min) {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
			}

			@media screen and (max-width: $screen-sm-max) {
				width: 100%;
			}
		}

		label {
			color: $white;
		}

		input.uploader {
			height: 22px;
		}

		.modal-dialog {
			label {
				color: $colorBlack;
			}
		}
	}
}

.project-wrap {
	@media screen and (min-width: $screen-md-min) {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}
}

.project__container {
	height: 100%;
	display: flex;
	flex-direction: column;
	&>.container {
		flex: 1;
	}
}

.project-priority-column {
	width: 50px;
}

.project-approved-dropdown-button {
	&.btn-xs {
		line-height: 1.42857;
	}
}

.portfolio-class {
  ul.dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
  }
}