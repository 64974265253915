.share-link-popup {
  &__body {
    margin: 20px 0px;
    background: rgb(255, 255, 255);
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .loader {
      margin-top: -5px;
      position: initial;
    }
  }
}
