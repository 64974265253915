.do-not-mail {
  &, &>div {
    background: #FF6400;
  }
  tr {
    background: #fff;
  }
}

.contact-age.more-than-59 {
  .form-control {
    border-color: orange;
  }
}

.contact-age.more-than-64 {
  .form-control {
    border-color: red;
  }
}

.available-tab a {
  box-shadow: 0 0 7px 4px rgba(71,135,177, 0.9);
}
.contact__main {
  max-height: calc(100% - 42px);
}

.contact__main,
.contact__tab-wrapper,
.contact-info__top-section {
  display: flex;
  overflow: hidden;
}
.contact-info__top-section-wrapper {
  flex: 1 2 215px;
  min-height: 215px;
  max-height: 235px;
  overflow: hidden;
}
.contact-info__top-section {
  max-height: 100%;
}
.contact-info__left-part {
  overflow: auto;
}
.contact-info__top-section {
  flex: 1;
}
.contact__tab-wrapper {
  flex: 2;
}
.contact__tab-wrapper,
.contact__main {
  flex-direction: column;
  justify-content: flex-start;
}
.contact__tab-wrapper {
  height: 100%;
}
.contact__tab-section {
  flex: 3 0 auto;
  display: flex;
  flex-direction: column;
  &>.row {
    flex: 1;
  }
}
.contact__tab-section .tab-content {
  flex: 1;
}
.contact-info__left-part {
  flex: 5.2;
}
.contact-info__right-part {
  flex: 6.8;
}
.contact-activity__container {
  height: 100%;
}

.contact__tab-section .text-bold label {
  font-weight: bold;
}
.contact__deal-note {
  border-bottom: 2px solid gray;
}

.contact-exec__details-body {
  overflow: auto;
  @for $i from 0 through 40 {
    @media screen and (min-height: 790px + 10 * $i) {
      max-height: 270px + 8 * $i;
    }
  }
}


.top-contact-lp-line {
  margin-left: 0;
  padding-left: 0;
  .form-group:first-child {
    margin-left: 0;
    padding-left: 0;
  }
}

.dba-disabled {
  cursor: not-allowed !important;
}

.contact__tables-panel {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 3px 3px;
  overflow-x: hidden;
}
