div.flip {
    .form-horizontal {
        label {
            padding-top: 4px;
        }
    }
}
.modal-dialog {
    i.fa-spinner.button {
        position: relative;
        left: 0;
        top: 0;
        margin: 0;
    }
}