.overflow-hidden {
  overflow: hidden;
}

.ib {
  display: inline-block;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.full-width-xxl {
  @media all and (max-width: $screen-xxl-max) {
    width: 100% !important;
  }
}

.flex-grow1 {
  flex-grow: 1;
}

.flex-basis0 {
  flex-basis: 0;
}

.react-datepicker__triangle {
  margin-left: 50px !important;
}

.flex-grid {
  display: flex;
  justify-content: space-between;
  &__align-center {
    align-items: center;
  }
  &__start {
    justify-content: flex-start;
  }
  &:before,
  &after {
    display: none;
  }
  &.flex-wrap-md {
    @media all and (max-width: $screen-md-max) {
      flex-wrap: wrap;
      .flex-basis0 {
        flex-basis: 100%;
      }
    }
  }
  &.flex-column-md {
    @media all and (max-width: $screen-md-max) {
      flex-direction: column;
    }
  }
}

.flex-stretched {
  display: flex;
  align-items: stretch;
}

.block-center {
  margin: 0 auto;
  text-align: center;
}

.vertical-middle {
  vertical-align: middle;
}

.vertical-t-bottom {
  vertical-align: text-bottom;
}

.m0 {
  margin: 0 !important;
}

.p0 {
  padding: 0 !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pl0 {
  padding-left: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.ml-4 {
  margin-left: -4px;
}

.ml-20 {
  margin-left: -20px;
}

@media all and (max-width: $screen-sm-max) {
  .mobile-ml0 {
    margin-left: 0;
  }
}

table.table-aligning {
  width: 100%;
  td {
    vertical-align: middle;
    padding: 2px 5px 3px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  .form-group {
    margin-bottom: 0;
  }
}

.min-h300 {
  min-height: 300px;
}

.w150 {
  width: 150px;
}

.w60 {
  width: 60px;
}

.w70 {
  width: 70px;
}

.min-w70 {
  min-width: 70px;
}

.text-normal {
  font-weight: normal;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-italic.text-muted > a {
  &,
  &:hover,
  &:focus {
    color: $text-muted;
  }
}

.i-dash {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    right: -7px;
    top: 9px;
    width: 4px;
    height: 1px;
    background: $colorBlack;
  }
}

.spinner {
  &--curtain {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.5;
    background: #e1e1e8;
  }
  &--icon {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 30px);
  }
}

.min-sizes {
  display: inline-block;
  min-height: 1em;
  min-width: 5em;
}

.min-height {
  display: inline-block;
  min-height: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.text-regular {
    font-weight: 500;
  }

  &.text-bolder {
    font-weight: 900;
  }
}

.bg-spinner {
  position: relative;
}

.no-overflow {
  overflow: hidden;

  .company-target-left-column {
    overflow: auto;
  }

  @media screen and (max-height: 800px) {
    .company-target-accordion {
      height: max-content;
      .panel-collapse {
        flex: 1;
        overflow-y: auto;
        max-height: 120px;
      }

      .panel-heading {
        min-height: 27px;
      }

      .panel-collapse.in {
        flex-grow: 10;
        max-height: 120px;
      }
    }
  }

  .flex-row {
    display: flex;
    height: 100%;
    overflow: hidden;
  }
}

.clickable {
  cursor: pointer;
}

.unclickable {
  pointer-events: none;
}

.disabled-link {
  color: $text-muted !important;
  text-decoration: none !important;
  cursor: not-allowed !important;
}

.y-scroll {
  overflow-y: auto !important;
}

.acc-close .note-content,
.acc-close .note-head {
  display: none;
}

.h100p {
  height: 100%;
}

.w90p {
  width: 90% !important;
}

.f15 {
  font-size: 15px !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mr2 {
  margin-right: 2px !important;
}

.hidden-flow-y {
  overflow-y: hidden;
}

.move-panel {
  position: relative;
  .move {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    cursor: move;
    opacity: 0;
  }
}

.truncate-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.all-h100p {
  height: 100% !important;

  position: relative;
  > div {
    height: 100% !important;
  }
  textarea {
    height: calc(100% - 16px) !important;
  }
  .clearfix > div {
    height: 100%;
  }
  .h100p {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.handle-draggable {
  cursor: move;
  button {
    cursor: pointer;
  }
}

.w-auto {
  width: auto;
}

.h160 {
  height: 160px !important;
}

.h105 {
  height: 105px !important;
}

.p-relative {
  position: relative;
}

.w105 {
  width: 105px !important;
}

.w135 {
  width: 135px !important;
}

.w160 {
  width: 160px !important;
}

.w180 {
  width: 180px !important;
}

.w260 {
  width: 260px !important;
}

.w320 {
  width: 320px !important;
}

.input-w120 input[type="text"] {
  width: 120px !important;
}

.select-w120 select {
  width: 120px !important;
}

.form-inline-block {
  display: inline-block !important;
}

.pos-relative {
  position: relative !important;
}

.panel-auto-scroll-y {
  overflow-y: auto;
}

.no-border {
  border: none !important;
}

.ag-cell {
  .ag-react-container {
    height: 100%;
  }
}

div.all-h100p {
  > div {
    height: 100%;
  }
}

.span-tooltip {
  position: relative;
}

.div-tooltip {
  position: absolute;
  top: 20px;
  left: 0;
}

.text-right {
  text-align: right;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

.checkbox-flex {
  .checkbox-wrap {
    display: flex;
  }
}

.radio-wrap {
  display: flex;
  align-items: center;
  margin: 5px 0;

  input {
    margin-top: -0.5px;
  }

  input,
  label {
    cursor: pointer;
  }
}

.radio-wrap--reverse {
  flex-direction: row-reverse;
  justify-content: flex-end;

  input {
    margin-right: 5px;
  }
}

.radio-wrap--start {
  align-items: flex-start;
}

.cursor-grab {
  cursor: initial !important;
}

.cursor-pointer {
  cursor: pointer;
}

.pl0 {
  padding-left: 0 !important;
}

.mb4 {
  padding-bottom: 4px;
}

.text-bold {
  font-weight: bold;
}

tr.deleted {
  color: #a94442;
  background: #f2dede;
}

a.tag-link {
  float: right;
  font-size: 14px;
  margin-right: 10px;
}

.des-change {
  .fa-times-circle {
    color: red;
  }
  .fa-check-circle {
    color: green;
  }
  th {
    text-align: center;
  }
  tr {
    td:nth-child(2) {
      text-align: center;
    }
  }
}

.font-bold {
  font-weight: 700;
}

.error-text {
  color: red;
  font-weight: bold;
  font-size: 14px;
}
