.no-hover-table{
    tr:hover {
        td{
            background: none !important;
        }
    } 
}

.next-actions {
    &-filters {
        min-width: 170px;
        overflow: auto;
        max-height: calc(100vh - 100px);

        &__counter {
            white-space: nowrap;
        }
    }
    &-table {
        position: absolute;
        bottom: 0;
        top: 50px;
        left: 0;
        right: 0;
    }
}

tr.backlogged {
    td {
        font-style: italic;
        color: #a6a6a6;
    }
    a.bl {
        background-color: #a6a6a6;
    }
}

a.bl {
    i.fa-spinner {
        margin: 0;
    }
}