.table-edit,
.table-edit-one-col {
  max-height: 225px;
  overflow-y: auto;
  background-color: $white;
  position: relative;

  th {
    white-space: nowrap;
  }

  .cell{
    padding-left: 5px;
    padding-right: 5px;
  }

  .cell:first-child {
    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .form-control--table {
    border: 1px solid #ccc !important;
  }
}

.table-edit-one-col {
  max-height: none;
  height: 110px;
}

.table-message {
  text-align: center;
  font-size: 13px;
}

.add-row {
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  margin-right: 4px;
  display: inline-block;
  line-height: 1;
}

.cell {
  height: $cell-height;

  &--marked {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      width: 0;
      height: 0;
      border-left: .5 * $triangle-size solid $red;
      border-top: .5 * $triangle-size solid $red;
      border-bottom: .5 * $triangle-size solid transparent;
      border-right: .5 * $triangle-size solid transparent;
    }
  }
}

.table-main {
  background-color: $white;
  margin-top: $gap;
  flex-grow: 1;
  margin-bottom: $footer-height;
  position: relative;
}

table.sort {
  thead {
    th {
      a {
        text-decoration: none;
      }
    }
  }
  input.form-control {
    border: 1px solid #ccc !important;
  }
}

.table.with-stats {
  margin-bottom: 0;
  table-layout: fixed;
  border-collapse: collapse;
}

.table.with-stats th {
  position: sticky;
  top: 0;
}

.table.with-stats tfoot tr td {
  position: sticky;
  background: #fff;
  height: 20px;
  vertical-align: middle;
  border: 0;
}

.table.with-stats tfoot tr:nth-child(1) td {
  bottom: 20px;
  border-top: 2px solid #000;
}

.table.with-stats tfoot tr:nth-child(2) td {
  bottom: 0;
}

.table.with-stats thead th:first-child {
  left: 0;
  z-index: 1;
}

.table.with-stats thead th,
.table.with-stats tbody td,
.table.with-stats tfoot td {
  padding: 1px;
}

.table.with-stats tfoot td:first-child {
  font-weight: 700;
}

.table.with-stats thead th:nth-child(1) {
  width: 150px;
}

.table.with-stats thead th:nth-child(11),
.table.with-stats tbody td:nth-child(11),
.table.with-stats thead th:nth-child(12),
.table.with-stats tbody td:nth-child(12),
.table.with-stats thead th:nth-child(13),
.table.with-stats tbody td:nth-child(13),
.table.with-stats thead th:nth-child(14),
.table.with-stats tbody td:nth-child(14) {
  width: 80px;
  text-align: center;
}

.table.with-stats thead th:nth-child(4),
.table.with-stats tbody td:nth-child(4),
.table.with-stats thead th:nth-child(5),
.table.with-stats tbody td:nth-child(5),
.table.with-stats thead th:nth-child(6),
.table.with-stats tbody td:nth-child(6),
.table.with-stats thead th:nth-child(7),
.table.with-stats tbody td:nth-child(7),
.table.with-stats thead th:nth-child(8),
.table.with-stats tbody td:nth-child(8),
.table.with-stats thead th:nth-child(9),
.table.with-stats tbody td:nth-child(9),
.table.with-stats thead th:nth-child(10),
.table.with-stats tbody td:nth-child(10),
.table.with-stats tfoot td:nth-child(3) {
  text-align: right;
}

.no-approval-list {
  &__row, &__title {
    color: #757575;
  }

  &__row {
    font-style: italic;
  }
}
