.company-details {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 3px 3px;
  overflow-x: hidden;

  .company-details__table {
    max-width: calc(100% - 6px);
    overflow: hidden;
    margin: 0 2px 5px 2px;

    td {
      min-width: 3em;
      max-width: 350px;
      padding-right: 2px;
      padding-left: 2px;
      width: 1px;
      span {
        max-width: calc(100% - 6px);
      }
    }

    .icon-wrapper {
      display: inline-block;

      .disabled-button {
        pointer-events: none;
      }
    }
  }

  .grow_1 {
    min-width: 150px;
  }
  .grow_2 {
    width: 300px;
  }
  .grow_3 {
    width: 450px;
  }

  .trade-shows-table {
    width: 710px;

    & .trade-show-year {
      width: 100px !important;
    }

    & .trade-show-name {
      width: 250px !important;
    }
  }
}

.company-details__table .disabled-button {
  pointer-events: none;
}

.company-details__table-wrapper {
  .industry {
    border: 1px solid #ccc;
    .fa-caret-down, .fa-caret-up {
      margin-left: 10px;
    }
  }
}
.accordion-middle-side .company-details__table .company-details__table-wrapper {
  display: block;
  overflow: auto;
}
.accordion-middle-side .company-details__table:last-child .company-details__table-wrapper {
  display: inline-block;
}

.accordion-middle-side .company-details__table .table td {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  &>* {
    text-overflow: ellipsis;
  }

  &>div,
  input {
    padding: 0;
    border: 0;
    margin: 0;
    min-width: 3em;
    display: inline-block;
    width: 100%;
    max-width: 350px;
  }
}

.company-details__table .table td {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  &>* {
    text-overflow: ellipsis;
  }

  &>div,
  input {
    padding: 0;
    border: 0;
    margin: 0;
    min-width: 3em;
    display: inline-block;
    width: 100%;
    max-width: 700px;
  }
}

.company__email-panel-body {
  padding-left: 5px;
  padding-right: 5px;
}

.company-target__files-upload-form,
.company-target__files-list {
  margin-left: 15px;
  margin-right: 15px;
}

.company-target__files-upload-form{
  margin-bottom: 5px;
}
.company-target__files-upload-field {
  border: 1px solid lightgray;
  border-radius: 3px;
}

#company-file-upload-file {
  height: 100px;
  display: block;
  opacity: 0
}

.company-target__financicals-ebitda {
  font-weight: bolder;
  font-size: 2em;
  height: 1.05em;
  padding: 0.2rem;
}

.dismis {
  &__panel {
    position: relative;
    .alert {
      position: absolute !important;
      top: 40px;
      left: 0;
      z-index: 1000;
    }
  }
}

.sources-table-container {
  display: flex;
  max-height: 100%;
  height: 100%;
  .sources-table {
    display: flex;
    flex-direction: column;
    min-height: 0;
    margin-bottom: 0;
    tr {
      display: flex;
      min-height: 1.5em;
    }
    th,
    td:not(.text-center) {
      width: 100%;
    }
    td[colspan]{
      padding: 3px;
    }
  }
  .sources-table__body {
    overflow: auto;
  }
}

.merged-company-note {
  max-width: 500px;
}
