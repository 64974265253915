.BrowseTabs {
	margin-top: 2vh;
	margin-bottom: 2vh;
	display: flex;
	height: 96vh;
	flex-direction: column;

	&-content {
		border-left: 1px solid #ddd;
		border-right: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
		padding: 2vh;
		height: 100%;

		.tab-pane {
			&.active {
				height: 100%;
			}
		}
	}

	&-inner {
		display: flex;
		flex-wrap: wrap;
		height: 100%;
	}

	#{$nav-link} {
		padding: 6px 8px;
		font-size: 12px;
		text-decoration: none;
		font-weight: bold;
	}

	.ag-header-cell-text, .ag-cell {
		font-size: 12px;
	}

	.dropdown-menu {
		min-width: initial;

		li {

			a {
				padding: 3px 9px 1px;
				font-size: 12px;
				text-decoration: none;
			}
		}
	}

	.BrowseControl-content--checkbox {
		padding: 5px 8px 5px 12px;
	}

	.BrowseControl-content--slider .form-control {
		font-weight: bold;
	}
}

.BrowseTabs-custom {
	margin-top: 75px;
}
