div.body-grid {
    a {
        text-decoration: none;
    }
}

.icon {
  &-approved {
    color: #16e219;
    &-not {
      color: #c6c6c6;
    }
  }
  &-rejected {
    color: red;
  }
}