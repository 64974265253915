label {
  font-size: 12px;
  margin-bottom: 0;
  line-height: 15px;
}

textarea {
  border: 1px solid $colorGrey-dark;
  display: block;
  width: 100%;
  resize: none;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  font-family: inherit;
}

.form-control {
  font-size: 12px;

  &:focus {
    border: 1px solid #ccc;
    box-shadow: none;
  }
}

.form-control[disabled], .form-control[readonly]{
  color: #9b9999;
}

.form-group {
  .input-group-addon {
    cursor: pointer;
    padding: 0 8px;
    &.disabled, &.disabled > * {
      cursor: $cursor-disabled;
    }
  }
}

.input-group-btn {
  button {
    height: $input-height;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.group-checkbox {
  &.flex {
    display: flex;

    .checkbox-wrap {
      margin-right: 10px;
    }
  }

  .checkbox-wrap {
    display: flex;
    align-items: center;
  }

  input[type=checkbox] {
    vertical-align: middle;
    margin: 0;
    height: 15px;
  }

  label {
    padding-left: 3px;
    font-weight: 400;
  }
}

@media (min-width: $screen-sm-min) {
  .baseline {
    vertical-align: baseline;
    margin-top: 10px;
  }
}

form {
  position: static;

  .loading {
    position: absolute;
    left: 0;
    top: 0;
    background: #666;
    z-index: 10;
    opacity: .5;
    width: 350px;
    height: 100%;
  }
  .fa-spinner{
    position: absolute;
    left: calc(50% - 23px);
    top: calc(43%);
    z-index: 11;
  }
}

input.changed {
  position:relative;
}

.checkbox-wrap.changed input[type=checkbox]::before {
  content: '';
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-left: 3px solid #c00;
  border-top: 3px solid #c00;
  border-bottom: 3px solid transparent;
  border-right: 3px solid transparent;
}

.form-inline.buyer-top-status-form .form-group {
  padding-left: 5px;
  padding-right: 5px;
}
.form-inline.buyer-top-status-form .control-wrap {
  display: inline-block;
  padding-left: 2px;
  padding-right: 2px;
  & .control-wrap {
    padding-left: 0;
    padding-right: 0;
  }
}
.control-wrap.changed,
.changed {
  position:relative;
  background: url($path-image + '/general/square_dirty.gif') no-repeat;
  background-position: left top !important;
}

.dirty-field {
  background-image: url($path-image + '/general/square_dirty.gif');
  background-repeat: no-repeat;
  background-position: left top !important;
  padding-left: 3px;

  &:hover, &:active, &:focus {
    outline: none;
  }
}

.float-none {

  @media (max-width: $screen-xs-max) {
      float: none!important;
  }
}

.modal,
.top_side {

  .btn-xs {
    line-height: 1.3;
    border-radius: 2px;
  }
}

.checkbox-wrap {
  line-height: 16px;
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    margin-top: 0;
    top: 0;
  }

  label {
    vertical-align: text-bottom;
    line-height: 1;
    cursor: pointer;
  }
}

.alert.input-custom-alert {
  margin: 0;
  padding: 5px 25px 5px 5px;
}

.modal-control {
  width: 100%;
   padding: 0 10px;

  &>label {
    margin-right: 5px;
  }

  &>.form-control {
    height: 23px;
  }
}

.has-feedback-email-verify {
  position: relative;
}

.has-feedback-email-verify .input-group-btn > .btn {
  width: 40px;
}

.has-feedback-email-verify .spinner--icon {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: relative;
}

.has-feedback-email-verify .spinner--icon .fa-spinner {
  margin: 0;
  left: 0;
  top: 0;
  position: relative;
}

.has-feedback-email-verify .form-control {
  padding-right: 20px;
}

.has-feedback-email-verify .icon-verify {
  width: 19px;
  height: 19px;
  line-height: 19px;
  position: absolute;
  top: 0;
  right: 37px;
  z-index: 3;
  pointer-events: none;
}

.has-feedback-email-verify .icon-verify.fa-check {
  color: $brand-success;
}

.has-feedback-email-verify .icon-verify.fa-close {
  color: $brand-danger;
}

.custom-upload-label input[type="file"]{
  display: none;
}

.custom-upload-label {
  margin-top: 1em;
}

.custom-upload {
  border: 0.1em $gray solid;
  margin-right: 0.6em;
  padding: 0.4em;

}

.custom-upload:hover {
  cursor: pointer;
}

.upload-error {
  color: $brand-danger;
  margin-top: 1em;
}

.upload-help {
  text-decoration: underline;
}

.max-date-help {
  text-decoration: underline;
  margin-left: 5px;
  margin-top: 5px;
}

.template-download {
  margin-top: 1em;
}

.horizontal-input {
  display: flex;
}

.report-select {
  margin-top: 10px;

  & label {
    font-weight: lighter;
    margin-left: 5px;
  }

  & input {
    margin: 0;
  }

  & div {
    display: flex;
    margin-bottom: 10px;
  }
}

.trade-show-field-text {
  color: $white;
  width: 100%;

  &:not(:last-child){
    margin-bottom: 20px;
  }

  & .website-field {

    & .input-group-addon {
      padding-bottom: 0;
    }
  }

  & label {
    width: 100%;
  }

  & textarea {
    height: 137px;
    width: 100%;
  }
}

.trade-show-year-select {
  width: fit-content;
  display: flex;
  & label {
    width: 100%;
    color: $white;
  }
}

.internal-popup-warning {
  margin: 5px 65px;
  padding: 5px;
}
