.react-contextmenu {
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    color: #373a3c;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    outline: none;
    opacity: 0;
    pointer-events: none;
    box-shadow: 0 0 5px 1px #999;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    z-index: 2000;
    pointer-events: auto;
}

.react-contextmenu-link {
    display: inline-block;
    width: 100%;
    padding: 3px 20px;
    clear: both;
    color: #373a3c;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
}

.react-contextmenu-link.active,
.react-contextmenu-link:hover {
    color: #fff;
    background-color: #0275d8;
    border-color: #0275d8;
    text-decoration: none;
}

.react-contextmenu-link--disabled{
    cursor: not-allowed;
    opacity: .6;
}

.react-contextmenu-item {
  cursor: pointer;
  margin-bottom: 3px;
  padding: 5px;
  &--selected {
    background: #eee;
  }
  &:last-child {
    margin-bottom: 0;
  }
  i {
    margin: 0 2px;
  }
}

// Some page context menu styles.
.project-contacts-settings .react-contextmenu.react-contextmenu--visible,
.contact-cell-value .react-contextmenu.react-contextmenu--visible {
  z-index: 10;
}

.menu-item-templates {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
} 