.bg-white {
  background-color: $colorWhite;
}

.chart-container,
.icon-container {
  border: 1px solid $colorGrey-dark-500;
  border-radius: $border-radius-large;
  overflow: hidden;
}

.icon-container {
  align-items: center;
}

.chart-wrapper {
  display: flex;
  align-items: center;
  height: 240px;
}

.user-chart-icon {
  display: flex;
  width: 70px;
  height: 70px;
  justify-content: center;
  align-items: center;
  margin: 0 16px 0 0;
  border-radius: 50%;
  background-color: $colorGrey-dark;
  font-size: 24px;
}
