.wrap-nav-tabs {
  padding-top: 10px;
}

.main-nav {
  width: 400px;
  margin: 0 auto;
  text-align: center;
  float: none;
  border-bottom: none;
  display: flex;
  justify-content: space-between;

  li  {
    &.nav-tab {
      &__target a{
        color: $btn-primary-color;
        background-color: $btn-primary-bg;
        border-color: $btn-primary-border;
      }
      &__buyer a{
        color: $btn-danger-color;
        background-color: $btn-danger-bg;
        border-color: $btn-danger-border;
      }
      &__executive a{
        color: $btn-success-color;
        background-color: $btn-success-bg;
        border-color: $btn-success-border;
      }
      &__lp a{
        color: $btn-warning-color;
        background-color: $btn-warning-bg;
        border-color: $btn-warning-border;
      }

      &__tradeGroups button {
        color: $btn-warning-color;
        background-color: $btn-warning-bg;
        border-color: $btn-warning-border;
        width: max-content;
        font-weight: bolder;
      }
    }

    flex-grow: 1;

    &[class^="nav-tab"] {
      a.active {
        color: $btn-default-color !important;
        background-color: $btn-default-bg !important;
        border-color: $btn-default-border !important;
      }
    }

    a, button {
      border-radius: 4px;
      border: 1px solid $colorGrey-dark;
      margin: 0 4px;
      padding: 3px 12px;
      font-size: 12px;
      background: $colorGrey-dark;
    }
  }
}

.left-nav {
  padding: 15px 0 0;
  border: none;
  border-bottom: 1px solid $colorGrey-dark;
  margin-bottom: 20px;

  li a {
    margin: 0 4px;
    padding: 8px 14px;
    font-size: 12px;
  }
}

.main-nav > li > a.active,
.main-nav > li > a.active:hover,
.main-nav > li > a.active:focus {
  border: 1px solid $colorGrey-dark;
  text-decoration: none;
}

.nav.nav-stacked {
  li>a {
    padding: 6px 10px;
    text-align: center;
    font-size: 12px;
  }

  li.active>a,
  li.active>a:hover,
  li.active>a:focus {
    background: $colorGrey-dark;
    color: $colorBlack;
  }
}

.nav-stacked {
  p {
    font-size: 12px;
    padding-top: 8px;
  }
}
