.loading-row {
    td {
        text-align: center;
    }
}
.user-tb-header-bg{
    background: #e1e1e8;
}

#userTableBody, #userTableHeader {
    td {
        word-wrap: break-word;
    }
}

#userTableHeader {
    tr {
        th {
            padding: 5px 1px
        }
    }
}