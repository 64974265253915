.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 17px;
  margin: 0 7px;
}

.switch .toggle-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $checked;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 4px;
  background-color: $slider;
  transition: .4s;
}

.toggle-checkbox:checked + .slider:before {
  left: 0;
}

.toggle-checkbox:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

.approve-switcher-block {
  display: flex;
  align-items: flex-end;
  margin-left: 4px;
}
